/* eslint-disable no-undef */
import { registerApplication, start } from 'single-spa';

const EXPRESS_MENU_APP_NAME = '@spoton/express-menu-web';

registerApplication({
    name: EXPRESS_MENU_APP_NAME,
    app: async () => {
        await System.import('@spoton/express-menu-web/config');
        return System.import('@spoton/express-menu-web');
    },
    activeWhen: () => true,
});

start();
