import {
    MenuRestaurantIcon,
    MenuRestaurantSolidIcon,
} from '@spotoninc/nexus-web-assets';

const createExpressMenuLink = () => ({
    name: 'Menu',
    url: `${window.location.origin}/menu/items/`,
    icon: MenuRestaurantIcon,
    activeIcon: MenuRestaurantSolidIcon,
    isAvailable: true,
    subLinks: [
        {
            name: 'Items',
            url: `${window.location.origin}/menu/items/`,
        },
        {
            name: 'Modifiers',
            url: `${window.location.origin}/menu/modifiers/`,
        },
    ],
});

export const createSideNavigationLinks = () => ({
    links: [createExpressMenuLink()],
    extraLinks: [],
});
