import { IAuthStateCookie } from './AppShell.types';
import { getConfigVar } from '../../../features/common/utils/config.utils.ts';

/**
 * A helper for getting an env variable which is expected to be a boolean
 *
 * @function checks if flag exists
 * @param {string} key env variable
 * @returns {boolean} true or false if flag exist or not
 */
export function getConfigFlag(key: string): boolean {
    return getConfigVar(key) === 'true';
}

/**
 * We need to prefix the cookie with QA in only the QA environment or else SSO will not work
 */
export function getCookieName(name: string): string {
    const cookiePrefix = getConfigFlag('REACT_APP_IS_QA') ? 'QA' : '';

    return cookiePrefix + name;
}
// https://stackoverflow.com/a/25490531
export function getCookieValue(name: string): string | undefined {
    const cookie = getCookieName(name);
    const b = document.cookie.match('(^|;)\\s*' + cookie + '\\s*=\\s*([^;]+)');

    return b ? b.pop() : undefined;
}

export const readCookieData = (): IAuthStateCookie | undefined => {
    const login = getCookieValue('CurrentLogin') ?? getCookieValue('NewLogin'); // Cookie name is different for the first time
    const refreshToken = getCookieValue('RefreshToken');

    if (login && refreshToken) {
        return {
            login,
            refreshToken,
        };
    }
};

export const isUserAuthenticated = () => {
    const cookieData = readCookieData();

    return cookieData && cookieData.refreshToken;
};

export const isProduction =
    (getConfigVar('REACT_APP_NODE_ENV') as string) === 'production';
