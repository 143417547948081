import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { NexusProvider } from '@spotoninc/nexus-react';

import { AppShell } from './app/components/AppShell';
// Global styles
import './global.css';
import { getConfigVar } from './features/common/utils/config.utils.ts';

(function appendImportMap() {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'systemjs-importmap';
    script.src = getConfigVar('REACT_APP_IMPORT_MAP_URL') ?? '';
    head.appendChild(script);
})();

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={null}>
            <NexusProvider theme={{ name: 'light' }}>
                {({ theme }) => (
                    <div className={theme.className}>
                        <AppShell />
                    </div>
                )}
            </NexusProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
);
