import { useState } from 'react';
import {
    SideBarLinkProps,
    SideBar,
    SideBarTopLinkProps,
    TopBar,
} from '@spotoninc/nexus-react';

import styles from './AppShell.module.scss';
import { allAppNames } from './../../utils/apps.utils';
import { isProduction } from './AppShell.utils';
import { createSideNavigationLinks } from './links.utils';
import { getConfigVar } from '../../../features/common/utils/config.utils.ts';

const sideNavigationLinks = createSideNavigationLinks();

export function AppShell(): JSX.Element {
    const appName = 'AX Dashboard';
    const userName = '';
    const userNameInitials = '';
    const userAddress = '';

    const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

    function toggleMainMenu(): void {
        setIsMainMenuOpen(!isMainMenuOpen);
    }

    function handleAppMenuClose(): void {
        setIsMainMenuOpen(false);
    }

    function onSelectLink(link: SideBarLinkProps | SideBarTopLinkProps) {
        window.location.assign(link.url);
    }

    function onLogout() {
        // in case of production, redirect to the dashboard's logout
        // in case of staging, redirect to the bff service auth logout that will redirect to the dashboard
        // AX will close the webview after detecting the logout url
        const url = isProduction
            ? getConfigVar('REACT_APP_EXTERNAL_APP_DASHBOARD') ?? ''
            : getConfigVar('REACT_APP_EXTERNAL_APP_BFF_AUTH_SERVICE') ?? '';

        window.location.href = `${url}logout`;
    }

    return (
        <div className={styles.App}>
            <TopBar
                className={styles.TopBar}
                applicationName={appName}
                currentPageName={appName}
                onToggleProductsMenu={() => {}}
                onToggleSideBar={toggleMainMenu}
                userInfo={{ name: userName, address: userAddress }}
                userMenu={{
                    avatar: { initials: userNameInitials },
                    options: [
                        {
                            label: 'Logout',
                            onClick: onLogout,
                        },
                    ],
                }}
            />
            <div className={styles.SideBarWrapper}>
                <SideBar
                    isOpen={isMainMenuOpen}
                    onClose={handleAppMenuClose}
                    onSelectLink={onSelectLink}
                    onOpenVerticalsMenu={() => {}}
                    applicationName={appName}
                    className={styles.SideBar}
                    {...sideNavigationLinks}
                />
            </div>
            <div className={styles.AppWrapper}>
                <div className={styles.AppContent}>
                    {allAppNames.map((el) => (
                        <div id={`single-spa-application:${el}`} key={el}></div>
                    ))}
                </div>
            </div>
        </div>
    );
}
